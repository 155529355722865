import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, IconButton, useTheme, Icon } from '@mui/material';
import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu"
import HomeIcon from "@mui/icons-material/Home";
import { Link } from "react-router-dom";

export const SideDrawer = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    setDrawerOpen(open);
  };

  const handleListItemClick = (path) => {
    toggleDrawer(false)(); // call toggleDrawer function to close the drawer
    // navigation logic here
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer(true)}
        sx={{ mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
        <List>
          <ListItem button key={"Home"} component={Link} to="/" onClick={() => handleListItemClick("/")}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary={"Home"} />
          </ListItem>
          <ListItem button key={"Contact"} component={Link} to="/contact" onClick={() => handleListItemClick("/contact")}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary={"Contact"} />
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};
