import * as React from 'react';
import Container from '@mui/material/Container';
import { Landing } from './Landing';
import Contact from './ContactPage/ContactPage';
import { Header } from './Header'; // Import Header
import { Footer } from './Footer'; // Import Footer
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import themeOptions from './themeOptions';
const theme = createTheme(themeOptions); // Use themeOptions here

export default function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        <Header /> 
        <Container maxWidth="lg">
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Container>
        <Footer /> 
      </Router>
    </ThemeProvider>
  );
}
