// Header.js
import * as React from 'react';
import { Typography, IconButton, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { SideDrawer } from './SideDrawer'; 

export const Header = () => {
  const theme = useTheme();

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        padding: '20px',
        textAlign: 'center',
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid item xs={2}>
        <SideDrawer />
      </Grid>
      <Grid item xs={8}>
        <Typography
          variant="h1"
          component="div"
          sx={{
            color: theme.palette.text.primary,
            fontSize: '4em',
          }}
        >
          Overthunk Designs
        </Typography>
      </Grid>
      <Grid item xs={2} />
    </Grid>
  );
}
