import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles'; // Import useTheme hook

export const Footer = () => {
    const theme = useTheme(); // Use the useTheme hook to get the current theme

    return (
        <Box
            component="footer"
            sx={{
                padding: '20px',
                textAlign: 'center',
                backgroundColor: theme.palette.background.paper, // Use the paper color as the background
                position: 'fixed',
                left: '0',
                bottom: '0',
                width: '100%',
            }}
        >
            <Typography
                variant="body2"
                sx={{ color: theme.palette.text.primary }} // Use the primary text color from the theme
            >
                © {new Date().getFullYear()} Overthunk Designs. All photos and images are subject to copyright law.
            </Typography>
        </Box>
    );
}
