import { Header } from "./Header";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Footer } from "./Footer";
import { ArtworkImageList } from "./ImageList";

export const Landing = () => {
  return (
      <Box sx={{ my: 4 }}>
        <ArtworkImageList/>
      </Box>
  );
}
