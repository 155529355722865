import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import ArrowBackIos from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';

const imageContext = require.context('./Images', false, /\.png$/);

const itemData = imageContext.keys().map((image) => ({
  img: imageContext(image),
  title: image,
}));

export function ArtworkImageList() {
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState(null);

  const handleClickOpen = (imageIndex) => {
    setOpen(true);
    setSelectedImage(imageIndex);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage + 1 === itemData.length ? 0 : prevSelectedImage + 1
    );
  };

  const handlePrevious = () => {
    setSelectedImage((prevSelectedImage) =>
      prevSelectedImage - 1 < 0 ? itemData.length - 1 : prevSelectedImage - 1
    );
  };

  return (
    <>
      <ImageList cols={3} gap={15}>
        {itemData.map((item, index) => (
          <ImageListItem key={item.img} onClick={() => handleClickOpen(index)}>
            <img src={item.img} alt={item.title} loading="lazy" />
          </ImageListItem>
        ))}
      </ImageList>

      <Dialog open={open} onClose={handleClose}>
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IconButton
            onClick={handlePrevious}
            sx={{
              position: 'absolute',
              left: 0,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
          >
            <ArrowBackIos />
          </IconButton>
          {selectedImage !== null && (
            <img
              src={itemData[selectedImage].img}
              alt={itemData[selectedImage].title}
              style={{ maxWidth: '100%', maxHeight: '80vh' }}
            />
          )}
          <IconButton
            onClick={handleNext}
            sx={{
              position: 'absolute',
              right: 0,
              color: 'white',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
            }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Dialog>
    </>
  );
}
