import React from "react";
import { Box, Typography, Link } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";

const Contact = () => {
  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>
        Contact Us <EmailIcon />
      </Typography>

      <Typography variant="body1">
        We'd love to hear from you. You can reach us at:
      </Typography>
      <Typography variant="body1">
        <Link href="mailto:admin@overthunkdesigns.com" color="secondary">
          admin@overthunkdesigns.com
        </Link>
      </Typography>
    </Box>
  );
};

export default Contact;
