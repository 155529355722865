const themeOptions = {
    palette: {
      mode: 'dark',
      primary: {
        main: '#3f51b5',
      },
      secondary: {
        main: '#f50057',
      },
      background: {
        default: '#333', // Darker background for the body
        paper: '#424242', // Lighter color for Header and Footer
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: '#333333',
          },
        },
      },
    },
  };
  
  export default themeOptions;
  